* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

#root {
    position: relative;
    min-height: 100vh;
    padding-bottom: 20px;
}

.App-content {
    text-align: center;
    position: relative;
}

#extraTags {
    width: 60%;
    margin: auto;
    text-align:left;
}
