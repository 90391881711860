.Tag-Wrapper {
    border-radius: 8px;
    padding: 4px;
    margin: 4px 4px 0px 0px;
    font-size: 12px;
    display: inline-block;
}

.Tag-Wrapper a {
    color: white;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
}
