.Book-Wrapper {
    float: middle;
    width: 50%;
    height: 100%;
    margin: auto;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: 8px;
    padding: 8px;
}

.Book-Thumbnail {
    width: 8.5em;
    float: left;
}

.Tags-Wrapper {
    margin-top: 8px;
}

.Book-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: auto;
    flex-wrap: wrap;
}

#edit-book,
#borrow-submit,
#isbn-remove,
#readlist-submit,
#readlist-remove,
#return-submit,
#upload-button {
    margin: 6px 6px;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid black;
    text-align: center;
    max-width: 192px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#Book-Title {
    font-size: 1.17em;
    margin: 0;
    font-weight: bold;
    width: fit-content;
}

#Book-SubTitle {
    font-size: 1em;
    margin: 0;
    font-weight: bold;
    width: fit-content;
}

#Book-Borrower {
    font-size: 1em;
    margin: 0;
    font-style: italic;
    font-weight: bold;
    width: fit-content;
}

#Book-Author,
#Book-Category,
#Book-Date,
#Book-Publisher,
#Book-Language,
#Book-Isbn {
    font-size: 1em;
    margin: 0;
    margin-bottom: 0.4em;
    width: fit-content;
    font-style: italic;
}

#Book-Body {
    font-size: 1em;
    margin: 5px;
    margin-bottom: 0.4em;
    margin-top: 12px;
}

#Book-Body li {
    margin-left: 1em;
}

#Book-Body p {
    margin-top: 0.5em;
}

#Book-Body h4 {
    margin-top: 1em;
}

#book-id {
    font-weight: bold;
}

@media screen and (min-width: 800px) {
    .Book-Wrapper {
        width: 65%;
    }
}
@media screen and (max-width: 799px) {
    .Book-Wrapper {
        width: 60%;
    }
}

.Book-Info {
    display: flex;
    flex-direction: column;
}

.E-book {
    display: block;
    padding: 10px;
}

/* Hiding standard file input */
#browse-button {
    display:none;
}
