.ReadingListPreview-Wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: 8px;
    padding: 8px;
    margin: 8px;
}

.CoverImage-Wrapper {
    margin-right: 8px;
}

.CoverImage-Wrapper img {
    min-height: 166px;
}

.MetaData-Wrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 8px;
}

.MetaData-Wrapper hr {
    margin-bottom: 8px;
    margin-top: 8px;
}

.MetaData-Wrapper a,
a:visited {
    color: black;
}

.metatext {
    margin-top: 100px;
}

div.bodytext {
    margin-top: 10px;
    width: 100%;
    height: 70px;
    overflow: hidden;
}

.tags {
    width: max-content;
}
.MetaData-Wrapper a {
    text-decoration: none;
}

.Expander {
    background-color: lightgray;
    margin-left: 2px;
    border-radius: 8px;
    padding: 2px;
    padding-left: 4px;
    padding-right: 4px;
}
