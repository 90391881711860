.App-header {
    text-align: center;
    position: relative;
    width: 100%;
    padding-top: 8px;
    background-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    overflow: visible;
}

.img-icon {
    width: 32px;
    height: 32px;
    vertical-align: middle;
    margin-left: 8px;
    margin-right: 8px;
}

.icon {
    color: black;
    vertical-align: middle;
    font-size: 32px;
}

.App-header input {
    width: 85%;
    padding: px;
    border: none;
}

.searchbar {
    width: 50%;
    margin: auto;
    border: 1px solid black;
    border-radius: 8px;
}

hr {
    margin-top: 8px;
}

.Tag-Search-Area {
    width: 50%;
    height: 28px;
    margin: auto;
    overflow: auto;
}

.dropdown {
    position: relative;
    display: inline-block;
    z-index: 1;
}

.dropbtn {
    background: white;
    border: none;
}

.item-list-wrapper {
    position: absolute;
    background: white;
    right: 0%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    min-width: 150px;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    border: black;
    border-radius: 20px;
}

.dropdown-header {
    color: black;
    padding: 5px;
    border-bottom: 1px solid black;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}
