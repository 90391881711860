.App-header {
    text-align: center;
    position: relative;
    width: 100%;
    padding-top: 8px;
    background-color: white;
    color: white;
}

.img-icon {
    width: 32px;
    height: 32px;
    vertical-align: middle;
    margin-left: 8px;
    margin-right: 8px;
}

.icon {
    color: black;
    vertical-align: middle;
    font-size: 32px;
}

.App-header input {
    width: 85%;
    padding: 8px;
    border: none;
}

.hr-without-search {
    margin-top: 42px;
}
