#isbn-input,
#isbn-submit,
#isbn-cancel,
#tag-submit {
    width: 100%;
    padding: 8px;
    margin-top: 8px;
    border-radius: 8px;
    font-size: 16px;
    border: 1px solid black;
}

#tag-input {
    padding: 4px;
    margin-top: 8px;
    border-radius: 8px;
    font-size: 16px;
    border: 1px solid black;
    width: 100%;
}

#book-title {
    font-size: 1.17em;
    margin: 0;
    font-weight: bold;
    width: fit-content;
}

#book-subtitle {
    font-size: 1em;
    margin: 0;
    font-weight: bold;
    width: fit-content;
}

#book-author,
#book-category,
#book-date,
#book-publisher,
#book-language,
#book-isbn {
    font-size: 1em;
    margin: 0;
    margin-bottom: 100px;
    width: fit-content;
    font-style: italic;
}

#isbn {
    font-weight: bolder;
}

#book-body {
    font-size: 1em;
    margin: 0;
    margin: 12px 0px 4px 0px;
}

.add-book-form {
    width: 60%;
    margin: auto;
}

.outline {
    margin-top: 16px;
    border: 1px solid black;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 800px) {
    .add-book-form {
        width: 65%;
    }
}
@media screen and (max-width: 799px) {
    .add-book-form {
        width: 80%;
    }
}
