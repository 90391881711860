/* 
TODO: Adapt styling for smaller screens (for example phones) 
      Use min/max-width attributes to accomplish this
*/

.BookPreview-Wrapper {
    border: 1px solid black;
    border-radius: 8px;

    padding: 8px;

    width: 60%;
    height: 184px;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
    margin-top: 8px;
}

.CoverImage-Wrapper {
    width: 30%;
    float: left;
}

.CoverImage-Wrapper img {
    height: 166px;
}

.MetaData-Wrapper {
    text-align: left;
    padding-left: 8px;
    width: 60%;
    float: right;
    position: relative;
    height: 100%;
}

.MetaData-Wrapper hr {
    margin-bottom: 8px;
    margin-top: 8px;
}

.metatext {
    margin-top: 8px;
}

.tags-wrapper {
    position: absolute;
    bottom: 0;
}

.MetaData-Wrapper a {
    text-decoration: none;
}

.Expander {
    background-color: lightgray;
    margin-left: 2px;
    border-radius: 8px;
    padding: 2px;
    padding-left: 4px;
    padding-right: 4px;
}
